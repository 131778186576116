<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>404 - {{ translations.tcPageNotFound }}</h1>
    </header>
    <div class="page-body container mx-med" style="text-align: center">
      <h4>{{ translations.tcRequestedUrlNotFound }}</h4>
      <div class='csmsg'>
        {{ translations.tcCallCustomerSupport }}
        <p class='mt-3'>{{ translations.tcClick }} <a href='#' @click.prevent='handleReturnHome'>{{ translations.tcHere
          }}</a> {{ translations.tcReturnToHomePage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'not-found',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
    }
  },
  methods: {
    handleReturnHome() {
      this.$router.push('/')
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  async created() {
    this.translations = await this.getViewTranslations()
  },
}
</script>

<style lang="scss">
.csmsg {
  font-weight: 600;
  margin-bottom: 30px;
}
</style>
